import styled from "styled-components";
import getFont from "../../../utils/getFont";

const Error = styled.span`
    display: block;
    margin-top: 5px;
    font-family: ${getFont()};
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1.8rem;
    color: #b42c2c;
`;

export default Error;
