import styled from "styled-components";
import Button from "./Button";
import PackageHero from "./PackageHero";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(Button)`
  padding: 3px 5px !important;
  min-width: 125px;
  text-transform: none;
  background: ${(props) =>
    props.active ? "var(--colour-tertiary-dmn)" : "transparent"};
  border-color: ${(props) =>
    props.active ? "var(--colour-tertiary-dmn)" : "white"};
`;

const Package = ({ active, click, data, roundel }) => {
  return (
    <Wrapper data-id={data.id}>
      <PackageHero active={active} click={click} data={data} roundel={roundel} />
      <StyledButton
        active={active}
        onClick={() => click(data)}
      >
        {active ? "Selected" : "Select"}
      </StyledButton>
    </Wrapper>
  );
};

export default Package;
