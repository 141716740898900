import styled, { css } from "styled-components";
import getFont from "../../utils/getFont";

const CalendarStyles = css`
  .flatpickr-calendar.static.open {
    top: unset;
    bottom: 0;
  }
`;

export const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 2px;
  position: relative;

  .theme--light & {
    border: 1px solid black;
  }

  & input:not([class*=numInput]),
  & select:not([class*=flatpickr]) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    font-family: ${getFont()};
    -webkit-appearance: none;
  }

  & input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

  & input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
  }

  & input[type="date"]::-webkit-clear-button {
    z-index: 1;
  }

  & select:disabled {
    cursor: not-allowed;
  }

  .flatpickr-wrapper {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    height: 24px;
  }

  .flatpickr-wrapper > input {
    font-size: 16px;
  }

  .flatpickr-calendar {
    top: calc(100% + 5px);
    background: #1c1c1c;
    border: 1px solid #707070;
    border-radius: 0;
    box-shadow: none;

    .theme--light & {
      background: #fff;
    }

    @media (max-width: 500px) {
      &.animate.open {
        animation: none !important;
      }
    }

    &.arrowBottom:before,
    &.arrowTop:before,
    &.arrowBottom:after,
    &.arrowTop:after {
      top: auto;
      bottom: calc(100% + 1px);
      border-top-color: transparent;
      border-bottom-color: #707070;
    }
  }

  .flatpickr-weekdays {
    .theme--light & {
      background: #fff;
    }
  }

  .flatpickr-month,
  .flatpickr-weekday,
  .flatpickr-monthDropdown-months {
    background: #1c1c1c !important;

    .theme--light & {
      background: #fff !important;
    }
  }

  .flatpickr-day.selected, .flatpickr-day.selected:hover {
    background: var(--colour-tertiary-dmn);
    border-color: var(--colour-tertiary-dmn);
  }

  .flatpickr-day:not(.selected):hover {
    background: var(--colour-primary-dmn);
    border-color: var(--colour-primary-dmn);
    color: #fff;
  }

  .flatpickr-day {
    color: white;
  }

  .flatpickr-day.flatpickr-disabled {
    color: #777777;
  }

  .flatpickr-current-month {
    color: white;
  }

  .flatpickr-weekday {
    color: white;
  }
  ${({ calendarAbove }) => calendarAbove && CalendarStyles}
`;

const Svg = styled.svg`
  height: 10px;
  width: 10px;
  color: black;
  transform: rotate(270deg);
  margin-right: 0;
`;

const Label = styled.label`
  opacity: 0.44;
  font-size: 12px;
  line-height: 20px;
`;

const Value = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  font-size: 12px;
  line-height: 29px;
  letter-spacing: 0.03em;

  span {
    height: 29px;
  }
`;

const Field = ({ children, label, value, calendarAbove = false }) => {
  return (
    <Wrapper calendarAbove={calendarAbove}>
      <Label>{label}</Label>
      {children}
      <Value>
        <span>{value}</span>
        <Svg viewBox="0 0 100 100">
          <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" />
        </Svg>
      </Value>
    </Wrapper>
  );
};

export default Field;
