import styled from "styled-components";

import BaseButton from "../../../ui/Button";
import getFont from "../../../../utils/getFont";

const Button = styled(BaseButton)`
  padding: 9px 10px 8px;
  width: 100%;
  background: ${(props) =>
    props.active ? "var(--colour-primary-dmn) !important" : "transparent"};
  border-color: ${(props) =>
    props.active ? "var(--colour-primary-dmn)" : "black"};
  border-radius: 0;
  font-family: ${getFont()};
  font-size: 1.6rem;
  font-weight: 300;
  color: ${(props) =>
    props.active ? "white" : "black"};
  line-height: 2.1rem;
  letter-spacing: 0.8px;
  opacity: 1;

  &:hover {
    background: var(--colour-primary-dmn);
    border-color: var(--colour-primary-dmn);
    color: white;
  }
`;

export default Button;
