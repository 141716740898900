import styled from "styled-components";
import getFont
 from "../../../utils/getFont";
const FormField = styled.label`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 310px;
    font-family: ${getFont()};
    font-size: 1.4rem;
    line-height: 1.8rem;

    > span {
        display: block;
        margin-bottom: 6px;
        letter-spacing: 0.7px;
    }

    > input {
        width: 100%;
        height: 45px;
        padding: 12px 14px;
        border: 1px solid #000;
        border-radius: 0;
        font-size: 1.6rem;
        line-height: 2.1rem;
    }
`;

export default FormField;
