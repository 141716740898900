import styled from "styled-components";
import Grid from "./Grid";

const GridWrap = styled(Grid)`
  flex-wrap: wrap;
  margin-bottom: ${props => props.marginBottom || 0};

  & > * {
    flex: auto;
    width: 33.33%;
    margin-bottom: 20px;
    max-width: 33.33%;

    @media (max-width: 640px) {
      width: 50%;
      max-width: 100%;
    }
  }
`;

export default GridWrap;
