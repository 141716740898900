import React from 'react';
import ReactDOM from 'react-dom';

import 'flickity/css/flickity.css';

import App from './App';

window.initDMNBookingWidget = () => {
  const dmnBookingElement = document.querySelector('#youngs-dmn-booking');

  window.openDmnBookingWidget = (initialType = "", id = undefined, localSiteName = undefined) => {

    if (window.screen.availWidth <= 500) {
      const { scrollY } = window;
      document.body.style.top = `-${scrollY}px`;
      document.body.style.position = "fixed";
    }

    const event = new Event('openWidget');

    event.detail = { initialType, localVenueId: id, siteName: localSiteName };

    window.dispatchEvent(event);
  }

  window.closeDmnBookingWidget = () => {
    if (document.body.style.top) {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }

    const event = new Event('closeWidget');
    window.dispatchEvent(event);
  }

  if (dmnBookingElement) {
    const venueId = dmnBookingElement.dataset.venue;

    if (venueId === "corporate") {
      import(/* webpackChunkName: 'flatpickr-light' */ "flatpickr/dist/themes/light.css");
    } else {
      import(/* webpackChunkName: 'flatpickr-dark' */ "flatpickr/dist/themes/dark.css");
    }

    ReactDOM.render(
      <React.StrictMode>
        <App
          endpoint={dmnBookingElement.dataset.endpoint}
          optionalPanels={JSON.parse(dmnBookingElement.dataset.optionalPanels)}
          venueId={venueId}
        />
      </React.StrictMode>,
      dmnBookingElement
    );
  }
};

document.addEventListener('DOMContentLoaded', window.initDMNBookingWidget);