import { differenceInMinutes } from "date-fns";
import React, { useEffect, useState } from "react";

import Field from "./Field";

const EndTimeField = ({ date, endTimeslots, maxDuration, minDuration, tableDispatch, time, value, handleDurationChange }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const newOptions = [];

        if ((typeof maxDuration === "number" || maxDuration === null) && (typeof minDuration === "number" || minDuration === null) && time) {
            const defaultMaxDuration = maxDuration === null ? 120 : maxDuration;
            const defaultMinDuration = minDuration === null ? 60 : minDuration;

            const dateTime = new Date(`${date}T${time}:00`);

            if (endTimeslots.length > 0) {
                newOptions.push({ label: "Select Time", value: "" });
            }

            for (let i = 0; i < endTimeslots.length; i += 1) {
                const timeslotDateTime = new Date(`${date}T${endTimeslots[i]}:00`);
                const difference = differenceInMinutes(timeslotDateTime, dateTime);

                if (difference >= defaultMinDuration && difference <= defaultMaxDuration) {
                    newOptions.push({ label: endTimeslots[i], value: difference });
                } else if (difference > defaultMaxDuration) {
                    break;
                }
            };

            if (newOptions.length) {
                const existingOption = (newOptions && newOptions.find((option) => `${option.value}` === `${value}`)) || newOptions[0];

                tableDispatch({
                    type: "SET_DURATION",
                    data: existingOption.value,
                });
            }
        }

        setOptions(newOptions);
    }, [maxDuration, minDuration, time]);

    const currentOption = (options && options.find((option) => `${option.value}` === `${value}`)) || { label: "" };

    return (
        <Field label="End Time" value={currentOption.label}>
            <select
                disabled={!options.length}
                value={value}
                onChange={(e) => handleDurationChange({ duration: e.target.value })}
            >
                {options.map((option) => <option key={`end-time-field-option-${option.value || "default"}`} value={option.value}>{option.label}</option>)}
            </select>
        </Field>
    )
}

export default EndTimeField;