import React, { useCallback, useContext, useMemo } from "react";

import Back from "../ui/Back"
import Button from "../ui/Button";
import ButtonGroup from "../ui/ButtonGroup";
import GridWrap from "../ui/GridWrap";
import Package from "../ui/Package";
import * as StyledOffers from "./styled";
import { tableStore } from "../../store/table";

const Offers = () => {
  const {
    actions,
    table: {
      availableOffers,
      offer,
      stepCache: { start: { activeOption: { offers } } }
    },
    tableDispatch
  } = useContext(tableStore);

  const allowedOffers = useMemo(
    () => offers.filter(({ id }) => availableOffers.includes(id)),
    [availableOffers, offers]
  );

  const handleBack = () => tableDispatch([
    { type: "PREVIOUS_STEP" },
    { type: "SET_OFFER", data: null }
  ]);
  const handleNextStep = () => tableDispatch({ type: "NEXT_STEP" });

  const handleSetOffer = useCallback((selectedOffer) => {
    if (offer && selectedOffer.id === offer.id) {
      tableDispatch({ type: "SET_OFFER", data: null });
    } else {
      actions.sendAnalyticsData({
        hitType: "event",
        eventAction: "Selection",
        eventLabel: `Offers - ${selectedOffer.title}`,
      });
      tableDispatch({ type: "SET_OFFER", data: selectedOffer });
    }
  }, [offer]);

  return (
    <>
      <Back
        onClick={handleBack}
      >
        <span>Back</span>
      </Back>
      <StyledOffers.Text>Would you like to add an offer to your booking?</StyledOffers.Text>
      <GridWrap>
        {Array.from(allowedOffers, (data) => (
          <Package
            key={data.id}
            data={data}
            click={handleSetOffer}
            active={offer && offer.id === data.id}
          />
        ))}
      </GridWrap>
      <ButtonGroup>
        <Button
          active
          onClick={handleNextStep}
        >
          Next Step
        </Button>
      </ButtonGroup>
    </>
  )
};

export default Offers;
