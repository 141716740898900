import styled from "styled-components";
import Text from "./Text";

const Wrapper = styled.div`
  margin: 20px 0;
`;

const Error = styled(Text)`
  color: #be7070;
  margin: 10px 0;
`;

const Errors = ({ errors }) => {
  if (errors?.length) {
    return <Wrapper>{Array.from(errors, error => <Error key={error}>{error}</Error>)}</Wrapper>;
  }

  return null
};

export default Errors;
