import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const Modal = ({ children }) => {
  const [modalContainer] = useState(
    typeof document !== "undefined" ? document.createElement("div") : undefined
  );

  useEffect(() => {
    const modalRoot = document.getElementById("modal-root") || undefined;

    if (modalRoot && modalContainer) modalRoot.appendChild(modalContainer);

    return () => {
      if (modalRoot && modalContainer) modalRoot.removeChild(modalContainer);
    };
  }, []);

  return typeof document !== "undefined" && modalContainer
    ? createPortal(children, modalContainer)
    : null;
};

export default Modal;
