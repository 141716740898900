import { useCallback, useEffect, useState } from "react";

function useClosestVenues({ endpoint, isEnabled, venueId }) {
  const [state, setState] = useState({ venues: undefined });

  const getClosestVenues = useCallback(async () => {
    const response = await fetch(
      `${endpoint}/v1/venue/${venueId}/closest`,
      { headers: { "Content-Type": "application/json" } }
    );

    if (!response.ok || response.status !== 200) {
      return;
    }

    const venues = await response.json();

    if (Array.isArray(venues) && setState) {
      setState((previousState) => ({
        ...previousState,
        venues
      }));
    }
  }, [endpoint, venueId]);

  useEffect(() => {
    if (typeof state.venues === "undefined" && isEnabled) {
      getClosestVenues();
    }
  }, [isEnabled, getClosestVenues]);

  return { venues: state.venues };
}

export default useClosestVenues;
