import styled from "styled-components";
import getFont from "../../../../utils/getFont";

const Title = styled.h1`
  margin-top: -10px;
  margin-bottom: 10px;
  font-family: ${getFont()};
  font-size: 2.1rem;
  color: black;
  line-height: 2.8rem;
`;

export default Title;
