import React from "react";
import { dateFormat } from "../helper";
import Button from "./ui/Button";
import ButtonGroup from "./ui/ButtonGroup";
import Field from "./ui/Field";
import Flatpickr from "./ui/Flatpickr";

const BookDirectForm = ({
  state,
  hotelCode,
  handleCheckInChange,
  handleCheckOutChange,
  handleOpen,
  today,
}) => {
  return (
    <form
      action={`https://direct-book.com/properties/${hotelCode}`}
      method="get"
      _lpchecked="1"
      target="_blank"
    >
      <Field
        label="Check In"
        value={state.checkIn ? dateFormat(state.checkIn) : "Select Date"}
      >
        <Flatpickr
          defaultDate={state.checkIn}
          handleChange={handleCheckInChange}
          handleOpen={handleOpen}
          minDate={today}
        >
          <input name="checkInDate" type="text" />
        </Flatpickr>
      </Field>
      <Field
        label="Check Out"
        value={state.checkOut ? dateFormat(state.checkOut) : "Select Date"}
      >
        <Flatpickr
          defaultDate={state.checkOut}
          handleChange={handleCheckOutChange}
          handleOpen={handleOpen}
          minDate={today}
        >
          <input name="checkOutDate" type="text" />
        </Flatpickr>
      </Field>
      <ButtonGroup>
        <Button active>Check Availability</Button>
      </ButtonGroup>
    </form>
  );
};

export default BookDirectForm;
