import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { format, isValid, parse } from "date-fns";

const Container = styled.div`
  margin-bottom: 15px;
`
const Label = styled.label`
  color: white;
  font-size: 14px;
  margin: 0px;
  text-transform: uppercase;
`

const InputCont = styled.div`
  display: grid;
  grid-template-columns: calc(33% - 6px) calc(33% - 6px) calc(33% - 5px);
  gap: 10px;
`

const Input = styled.input`
  padding: 10px;
  display: block;
  border-radius: 0;
  outline: none;
  border: 0;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[data-error="true"] {
    border: 1px solid red;
  }
`

const Error = styled.p`
  color: red;
`

export default function DobField({callback}){
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const [dayError, setDayError] = useState(false);
  const [monthError, setMonthError] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const currentYear = format(new Date(), "yyyy");
  const minYear = 1920;

  const dob = useMemo(() => {
    if (
      ![dayError, monthError, yearError].includes(true) &&
      day.length === 2 &&
      month.length === 2 &&
      year.length === 4
    ) {
      return `${year}-${month}-${day}`;
    }

    return undefined;
  }, [day, dayError, month, monthError, year, yearError]);

  const isValidYear = useMemo(() => {
    if (!dob) return false;
    const parsedDate = parse(dob, "yyyy-MM-dd", new Date());
    const isValidDate = isValid(parsedDate);
    return isValidDate;
  }, [dob]);

  // Validate day
  useEffect(() => {
    if (day === "") return;

    if (+day < 31 && +day > 0 && day.length === 2) {
      setDayError(false);
      monthRef.current?.focus();
    } else {
      setDayError(true);
    }
  }, [day]);

  // Validate Month
  useEffect(() => {
    if (month === "") return;
    if (+month < 12 && +month > 0 && month.length === 2) {
      setMonthError(false);
      yearRef.current?.focus();
    } else {
      setMonthError(true);
    }
  }, [month]);

  // Validate Year
  useEffect(() => {
    if (year === "") return;
    if (+year < +currentYear && +year > minYear && year.length === 4) {
      setYearError(false);
    } else {
      setYearError(true);
    }
  }, [currentYear, year]);

  useEffect(() => {
    if (isValidYear) callback(dob);
    else  callback(undefined);
  }, [dob, isValidYear]);

  const errorMessage = useMemo(() => {
    if (dob && !isValidYear) {
      return "Invalid Date";
    }
    return false;
  }, [dob]);

  return (
    <Container>
      <Label htmlFor="">Date of Birth</Label>
      <InputCont>
        <Input
          type="number"
          min={1}
          max={31}
          placeholder="DD"
          onChange={(event) => {
            setDay(event.target.value);
          }}
          data-error={dayError || errorMessage !== false}
        />
        <Input
          type="number"
          min={1}
          max={12}
          placeholder="MM"
          onChange={(event) => {
            setMonth(event.target.value);
          }}
          ref={monthRef}
          data-error={monthError || errorMessage !== false}
        />
        <Input
          type="number"
          min={minYear}
          max={currentYear}
          placeholder="YYYY"
          onChange={(event) => {
            setYear(event.target.value);
          }}
          data-error={yearError || errorMessage !== false}
        />
      </InputCont>

      {errorMessage && (
      <Error>
        {errorMessage}
      </Error>
      )}
    </Container>
  )
}
