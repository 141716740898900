import React, { useContext } from "react";
import styled from "styled-components";

import { dateFormat, isEnquiry } from "../helper";
import { tableStore } from "../store/table";
import Back from "./ui/Back";
import Button from "./ui/Button";
import ButtonGroup from "./ui/ButtonGroup";
import Errors from "./ui/Errors";
import FieldLarge from "./ui/FieldLarge";
import getFont from "../utils/getFont";
import DobField from "./ui/DobField";

const Wrapper = styled.div`
  max-width: 408px;
  margin: 0 auto;
`;

const Title = styled.h3`
  color: white;
  font-size: 14px;
  margin: 0;
  text-transform: uppercase;

  .theme--light & {
    color: black;
  }
`;

const Subtitle = styled.h4`
  color: white;
  font-size: 14px;
  margin: 15px 0 0;
  font-weight: normal;

  .theme--light & {
    color: black;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  border: 0;
  min-height: 100px;
  padding: 10px;
  font-family: ${getFont()};

  .theme--light & {
    border: 1px solid black;
    border-radius: 0;
  }
`;

const CheckBox = styled.div`
  display: flex;

  & input {
    flex-shrink: 0;
    margin: 2px 0 0;
    border: 1px solid white;

    .theme--light & {
      border: 1px solid black;
    }
  }

  & label {
    color: white;
    font-size: 12px;
    line-height: 19px;
    margin-left: 10px;

    .theme--light & {
      color: black;
    }
  }
`;

const Details = () => {
  const { table, tableDispatch, actions } = useContext(tableStore);

  const goBack = () => {
    tableDispatch([
      { type: "PREVIOUS_STEP" },
    ]);
  };

  return (
    <>
      <Back onClick={() => goBack()}><span>Back</span></Back>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          // GA4
          if (
            table?.bookingTypeTitle &&
            typeof window.dataLayer !== 'undefined'
          ) {
              const [h, m] = table?.time.split(":");
              const totalHours = Number(h) + Math.floor(table?.duration / 60);
              const totalMinutes = Number(m) + (Number(table?.duration) % 60);
              const endTime = totalHours.toString().padStart(2, "0")+":"+totalMinutes.toString().padStart(2, "0");

              const dataGA = {
                venue_id: table?.venueId,
                booking_category: table?.stepCache.start.activeOption.title,
                booking_type: table?.bookingTypeTitle,
                date: table?.date,
                guests: table?.numPeople,
                start_time: table?.time,
                end_time: endTime
              };

              if(isEnquiry(table?.check)){
                dataGA["event"] = 'table_enquiry';
                dataGA["gtm_tag_name"] = 'GA4 - Table booking - enquiry';
              }else{
                dataGA["event"] = 'table_booking';
                dataGA["gtm_tag_name"] = 'GA4 - Table booking - confirmation';
                dataGA["viewed_offer"] = table?.offer;
                dataGA["package"] = table?.package;
                dataGA["button_location"] = "Modal";
              }
              //
              window.dataLayer.push(dataGA);
          }
          // end GA4

          if (typeof table.check === "object" && !table.check.canBookViaApi && table.check.webBookingUrl) {

            actions.sendAnalyticsData({
              hitType: "event",
              eventAction: "Submit",
              eventLabel: `${isEnquiry(table.check) ? "Enquiry" : "Booking"} - Deposit`,
            });

            const { comments, email, firstName, lastName, phone } = table;

            const returnUrl = table.check.webBookingUrl + encodeURI(`&email=${email}&first_name=${firstName}&last_name=${lastName}&notes=${comments}&phone=${phone}&return_url=${window.location.origin}${window.location.pathname}?modal=booking`);

            window.location = returnUrl;

            return;
          }

          actions.completeBooking();
        }}
      >
        <Wrapper>
          <Title>Your Details</Title>
          <FieldLarge
            label="First Name*"
            value={table.firstName}
            hideValue
          >
            <input
              required
              value={table.firstName}
              type="text"
              onChange={(e) => {
                tableDispatch({
                  type: "SET_FIRST_NAME",
                  data: e.target.value,
                });
              }}
            />
          </FieldLarge>
          <FieldLarge
            label="Last Name*"
            value={table.lastName}
            hideValue
          >
            <input
              required
              value={table.lastName}
              type="text"
              onChange={(e) => {
                tableDispatch({
                  type: "SET_LAST_NAME",
                  data: e.target.value,
                });
              }}
            />
          </FieldLarge>
          <FieldLarge
            label="Phone Number*"
            value={table.phone}
            hideValue
          >
            <input
              required
              value={table.phone}
              type="text"
              onChange={(e) => {
                tableDispatch({
                  type: "SET_PHONE",
                  data: e.target.value,
                });
              }}
            />
          </FieldLarge>
          <FieldLarge label="Email*" value={table.email} hideValue>
            <input
              required
              value={table.email}
              type="email"
              onChange={(e) => {
                tableDispatch({
                  type: "SET_EMAIL",
                  data: e.target.value,
                });
              }}
            />
          </FieldLarge>

          <DobField
            callback={(value) => {
              tableDispatch({
                type: "SET_DOB",
                data: value,
              });
            }}
          />

          <CheckBox>
            <input
              checked={table.promo}
              type="checkbox"
              onChange={(e) => {
                tableDispatch({
                  type: "SET_PROMO",
                  data: e.target.checked,
                });
              }}
            />
            <label>
              Sign me up for offers, news and promotions, mainly via email
            </label>
          </CheckBox>
          <Subtitle>Additional Comments?</Subtitle>
          <TextArea
            value={table.comments}
            onChange={(e) => {
              tableDispatch({
                type: "SET_COMMENTS",
                data: e.target.value,
              });
            }}
          />
          <Subtitle>Booking Policy</Subtitle>
          <CheckBox>
            <input
              required
              checked={table.policy}
              type="checkbox"
              onChange={(e) => {
                tableDispatch({
                  type: "SET_POLICY",
                  data: e.target.checked,
                });
              }}
            />
            <label
              dangerouslySetInnerHTML={{
                __html:
                  table.check?.dateSettings?.bookingPolicy ||
                  "Please note all tables are held for 15 minutes only.",
              }}
            />
          </CheckBox>
        </Wrapper>
        <Errors errors={table.errors} />
        <ButtonGroup>
          <Button active disabled={table.loading}>
            {isEnquiry(table.check) ? "Enquire" : "Confirm"}
          </Button>
        </ButtonGroup>
      </form>
    </>
  );
};

export default Details;
