import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  max-width: 335px;
  padding-right: 20px;
  padding-left: 20px;

  @media (max-width: 500px) {
    padding-top: 20px
    padding-right: 0;
    padding-bottom: 20px;
    padding-left: 0;
  }

  &,
  > h6 {
    font-size: 16px;
    line-height: 20px;
    color: white;

    .theme--light & {
      color: black;
    }
  }

  > h6 {
    text-transform: uppercase;
    margin-bottom: 13px;
  }

  > p + p {
    margin-top: 7px;
  }

  > span {
    display: block;
    margin-top: 7px;
    font-size: 12px;
    line-height: 14px;
    color: #5f5f5f;
  }
`

export default Content;
