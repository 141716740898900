import styled from "styled-components";

import Base from "../../../ui/Button";

const Button = styled(Base)`
  display: block;
  margin: 15px auto 0;
  padding: 3px 5px;
  min-width: 125px;
  text-transform: none;
  color: ${(props) => (props.active ? "white" : "black")};
  background: ${(props) =>
    props.active ? "var(--colour-tertiary-dmn)" : "transparent"};
  border-color: ${(props) =>
    props.active ? "var(--colour-tertiary-dmn)" : "black"};
`;

export default Button;
