import styled from "styled-components";
import getFont from "../../../utils/getFont";

const Button = styled.button`
  width: 199px;
  padding: 1rem 2rem;
  background: black;
  border: 1px solid;
  border-color: black;
  border-radius: 0;
  font-family: ${getFont()};
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.1rem;
  letter-spacing: 0.8px;
  color: white;
  text-transform: uppercase;
  opacity: ${props => !props.disabled ? "1" : "0.6"};

  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export default Button;
