import flatpickr from "flatpickr";
import React, { createRef, useCallback, useEffect } from "react";
import { differenceInCalendarMonths, parse } from "date-fns"

const Flatpickr = ({
  children: child,
  className,
  defaultDate,
  enable = [],
  handleChange,
  handleOpen,
  minDate,
  options = {},
  redraw,
  startAtDate
}) => {
  const flatpickrRef = createRef();

  // const defaultHandleOpen = useCallback((selectedDates, dateStr, instance) => {

  //   if (startAtDate) {
  //     const diff = differenceInCalendarMonths(
  //       startAtDate,
  //       parse(
  //         `${instance.currentYear}-${instance.currentMonth + 1}`,
  //         "yyyy-M",
  //         new Date()
  //       )
  //     );
  //     instance.changeMonth(diff)
  //   }
  // }, [startAtDate]);

  useEffect(() => {
    let flatpickrInstance;

    const { current: flatpickrSelector } = flatpickrRef;

    if (flatpickrSelector) {
      const minDateInt = Number(minDate.replace(/-/g, ""));

      flatpickrInstance = flatpickr(flatpickrSelector, {
        altInput: true,
        altFormat: "J M Y",
        defaultDate: defaultDate || undefined,
        disableMobile: true,
        locale: {
          firstDayOfWeek: 1,
        },
        position: "above",
        minDate,
        onChange: (_, date) => {
          const dateInt = Number(date.replace(/-/g, ""));

          if (dateInt >= minDateInt) {
            handleChange(date);
          } else if (dateInt <= minDateInt) {
            flatpickrInstance.setDate(minDate, true);
          }
        },

        onOpen: handleOpen,
        onReady: () => {
          if (defaultDate) {
            handleChange(defaultDate);
          }
        },
        static: true,
        ...options,
      });

      if (flatpickrInstance.isMobile) {
        flatpickrInstance.config._minDate = undefined;
        flatpickrInstance.config._maxDate = undefined;
      }
    }

    return () => flatpickrInstance && flatpickrInstance.destroy();
  }, []);

  useEffect(() => {

    const { current: flatpickrSelector } = flatpickrRef;

    if (flatpickrSelector) {
      const flatpickrInstance = flatpickrSelector._flatpickr;

      if (flatpickrInstance && startAtDate) {

        const diff = differenceInCalendarMonths(
          startAtDate,
          parse(
            `${flatpickrInstance.currentYear}-${flatpickrInstance.currentMonth + 1}`,
            "yyyy-M",
            new Date()
          )
        );
        flatpickrInstance.changeMonth(diff)

      }

    }

  }, [startAtDate]);

  useEffect(() => {
    const { current: flatpickrSelector } = flatpickrRef;

    if (flatpickrSelector) {
      const flatpickrInstance = flatpickrSelector._flatpickr;

      if (flatpickrInstance && defaultDate) {
        flatpickrInstance.setDate(defaultDate, true);
      }
    }
  }, [defaultDate]);

  useEffect(() => {
    const { current: flatpickrSelector } = flatpickrRef;

    if (flatpickrSelector && redraw) {
      const flatpickrInstance = flatpickrSelector._flatpickr;

      flatpickrInstance.config.enable = enable;
      flatpickrInstance.redraw();

      if (flatpickrInstance.selectedDates[0] && !flatpickrInstance.isEnabled(flatpickrInstance.selectedDates[0])) {
        flatpickrInstance.setDate(undefined);
        handleChange("");
      } else {
        handleChange();
      }
    }
  }, [redraw])

  return React.cloneElement(child, { className, ref: flatpickrRef });
};

export default Flatpickr;
