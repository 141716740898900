import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 40px 20px;
  color: white;

  .theme--light & {
    color: black;
  }
`;

const BookOther = ({ optionalPanels }) => {
  const { general_content: content } = optionalPanels;

  return (
    <Wrapper dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
};

export default BookOther;
