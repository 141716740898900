/* eslint-disable react/no-array-index-key */
import React, { Fragment, useMemo } from "react";

import { Hero } from "../../ui/PackageHero";
import Slide from "../../ui/Slide";
import * as StyledVenue from "./styled";

const Venue = ({ active, address, area, distance, id, imageUrl, onSelect, title }) => {
  const formattedDistance = useMemo(() => {
    return Math.round(distance * 10) / 10
  }, [distance]);

  const handleSelect = () => onSelect(id);

  return (
    <StyledVenue.Wrapper>
      <Hero>
        <Slide>
          <img alt={title} src={imageUrl || "https://youngscorporate-legacy.standard.aws.prop.cm/youngs/uploads/sites/2/2021/07/square.jpg"} />
        </Slide>
      </Hero>
      <StyledVenue.Title>{title}</StyledVenue.Title>
      {area && <StyledVenue.Text>{area}</StyledVenue.Text>}
      <StyledVenue.Text fontWeight="bold">
        {address.map((line, index) => (
          <Fragment key={`${id}-address-line-${index}`}>
            {line}
            {index < address.length - 1 ? (
              <>
                ,
                <br />
              </>
            ) : ""}
          </Fragment>
        ))}
      </StyledVenue.Text>
      <StyledVenue.Distance>
        {formattedDistance}
        {" "}
        miles away
      </StyledVenue.Distance>
      <StyledVenue.Button
        active={active}
        onClick={handleSelect}
      >
        {active ? "Selected" : "Select Venue"}
      </StyledVenue.Button>
    </StyledVenue.Wrapper>
  );
};

export default Venue;
