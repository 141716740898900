import styled from "styled-components";

import Base from "../../ui/Text";

const Text = styled(Base)`
  max-width: 525px;
  margin-top: 12px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: normal;
`;

export default Text;
