import React from "react";

import Modal from "../Modal/Modal";
import * as StyledPopup from "./styled";

const Popup = ({ children, handleClose }) => {
  return (
    <Modal>
      <StyledPopup.Wrapper>
        <StyledPopup.Inner>
          <StyledPopup.CloseButton onClick={handleClose} type="button">
            <span aria-label="Close" />
          </StyledPopup.CloseButton>
          {children}
        </StyledPopup.Inner>
      </StyledPopup.Wrapper>
    </Modal>
  );
}

export default Popup;
