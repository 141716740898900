import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import AlternateTimes from "./AlternateTimes";
import Confirmed from "./Confirmed";
import Details from "./Details";
import Offers from "./Offers/Offers";
import Packages from "./Packages";
import Start from "./Start/Start";
import Summary from "./Summary";
import { tableStore } from "../store/table";
import VenueSelect from "./VenueSelect/VenueSelect";
import { scrollTo } from "scroll-js";

const Wrapper = styled.div`
  padding: 40px 20px;
`;

const BookTable = ({ modalRef, siteName }) => {
  const {
    isActive,
    table,
    actions: { sendAnalyticsData },
  } = useContext(tableStore);

  const steps = {
    alternate: AlternateTimes,
    confirmed: Confirmed,
    details: Details,
    offers: Offers,
    packages: Packages,
    start: Start,
    "venue-select": VenueSelect
  };

  const CurrentStep = steps[table.step];

  useEffect(() => {
    if (!isActive || !table.bookingTypeTitle) return;
    sendAnalyticsData({
      hitType: "event",
      eventAction: "Selection",
      eventLabel: `Type - ${table.bookingTypeTitle}`,
    });
  }, [isActive, table.bookingTypeTitle]);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }, [table.step])

  return (
    <>
      {!["start", "venue-select"].includes(table.step) ? <Summary /> : null}
      <Wrapper>
        {siteName && <p id="dmn-site-name">{siteName}</p>}
        <CurrentStep modalRef={modalRef} />
      </Wrapper>
    </>
  );
};

export default BookTable;
