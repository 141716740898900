import { useCallback, useEffect, useState } from "react";

function downloadScript(params) {
  const query = Object.keys(params).map(key => `${key}=${params[key]}`).join("&");

  const script = document.createElement("script");

  script.type = "text/javascript";
  script.src = `https://maps.googleapis.com/maps/api/js?${query}`;

  document.body.appendChild(script);
}

function useGooglePlaces() {
  const [state, setState] = useState({
    coords: { lat: undefined, lng: undefined },
    isReady: false
  });

  const ref = useCallback((input) => {
    if (input !== null && state.isReady) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        input,
        { componentRestrictions: { country: "uk" } }
      );

      window.google.maps.event.addListener(autocomplete, "place_changed", () => {
        const place = autocomplete.getPlace();

        if (setState) {
          setState((previousState) => ({ ...previousState, coords: {
            lat: place?.geometry?.location?.lat(),
            lng: place?.geometry?.location?.lng(),
          }}));
        }
      });
    }
  }, [state.isReady]);

  const handleIsReady = () => setState && setState((previousState) => ({ ...previousState, isReady: true }));

  const handleScroll = () => {
    const { activeElement } = document;
    const container = document.querySelector(".pac-container");

    if (activeElement.classList.contains("js-venue-select-input") && container) {
      container.style.top = `${window.scrollY + activeElement.getBoundingClientRect().bottom}px`
    }
  };

  useEffect(() => {
    const setupGooglePlaces = () => {
      if (typeof window.google?.maps?.places?.Autocomplete !== "undefined") {
        handleIsReady();
      } else {
        window.googlePlacesReady = handleIsReady;
  
        downloadScript({
          key: "AIzaSyAUcOKKBawPt9qU-s74SDRPLt1BCrIGEJY",
          v: "3", 
          libraries: "places",
          callback: "googlePlacesReady",
        })
      }
    }

    if (document.readyState === "complete") {
      setupGooglePlaces();
    } else {
      window.addEventListener("load", setupGooglePlaces, { once: true });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return { coords: state.coords, ref }
}

export default useGooglePlaces;
