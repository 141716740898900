/* eslint-disable jsx-a11y/control-has-associated-label */
import { addDays, format, isAfter, isBefore, isEqual } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import { scrollTo } from "scroll-js";
import styled from "styled-components";

import BookDirectForm from "./BookDirectForm";
import EtchForm from "./EtchForm";
import GuestLineForm from "./GuestLineForm";
import ProfitRoomForm from "./ProfitRoomForm";
import { Wrapper as FieldWrapper } from "./ui/Field";

const Wrapper = styled.div`
  padding: 40px 20px;

  & > *:not(form) {
    color: white;

    .theme--light & {
      color: black;
    }
  }

  & + & {
    padding-top: 0;
  }

  ${FieldWrapper} + ${FieldWrapper} {
    margin-top: 20px;
  }
`;

const BookRoom = ({ modalRef, optionalPanels }) => {

  const {
    book_a_room_content: content,
    book_a_room_venues: venues = [],
    etch_hotel_id: etchHotelCode,
    guest_line_hotel_id: guestLineCode,
    book_direct_hotel_id: bookDirectHotelId,
    profit_room_id: profitRoomHotelId

  } = optionalPanels;

  const today = new Date().toISOString().split("T")[0];

  const [state, setState] = useState({
    checkIn: today,
    hasUserSelectedCheckOut: false,
    venue: undefined,
    venues: venues.filter(
      (venue) =>
        (venue.etchId && venue.etchId !== null) ||
        (venue.guestLineId && venue.guestLineId !== null)
    ),
  });

  const venueName = useMemo(() => {
    if (typeof state.venue !== "undefined") {
      const venue = state.venues.find(({ etchId, guestLineId }) => {
        if (etchId) {
          return etchId === state.venue;
        }

        if (guestLineId) {
          return guestLineId === state.venue;
        }
      });

      if (venue) {
        return venue.title;
      }
    }

    return "";
  }, [state.venue, state.venues]);

  const guestLineForm = useMemo(() => {
    if (typeof state.venue !== "undefined") {
      let isGuestLine;

      state.venues.find(({ etchId, guestLineId }) => {
        if (etchId === state.venue) {
          isGuestLine = false;
        }

        if (guestLineId === state.venue) {
          isGuestLine = true;
        }
      });

      return isGuestLine;
    }

    return false;
  }, [state.venue]);

  const handleCheckInChange = (dateStr) => {
    setState((previousState) => {
      const date = new Date(dateStr);

      let { checkOut, hasUserSelectedCheckOut } = previousState;

      if (
        typeof checkOut === "undefined" ||
        isBefore(new Date(checkOut), date) ||
        isEqual(new Date(checkOut), date)
      ) {
        checkOut = format(addDays(date, 1), "yyyy-MM-dd");
        hasUserSelectedCheckOut = false;
      } else if (
        !hasUserSelectedCheckOut &&
        isAfter(new Date(checkOut), date)
      ) {
        checkOut = format(addDays(date, 1), "yyyy-MM-dd");
      }

      return {
        ...previousState,
        checkIn: dateStr,
        checkOut,
        hasUserSelectedCheckOut,
      };
    });
  };

  const handleCheckOutChange = (date) => {
    setState((previousState) => {
      if (date !== previousState.checkOut) {
        return {
          ...previousState,
          checkOut: date,
          hasUserSelectedCheckOut: true,
        };
      }
      return previousState;
    });
  };

  const handleOpen = useCallback(() => {
    if (modalRef && modalRef.current) {
      scrollTo(modalRef.current, { top: 298, behavior: "smooth" });
    }
  }, [modalRef]);

  const formMarkup = () => {
    switch (true) {
      case Boolean(guestLineForm || guestLineCode): {
        return (
          <GuestLineForm
            state={state}
            setState={setState}
            venueName={venueName}
            guestLineCode={guestLineCode}
            handleCheckInChange={handleCheckInChange}
            handleCheckOutChange={handleCheckOutChange}
            handleOpen={handleOpen}
            today={today}
          />
        );
      }

      case Boolean(profitRoomHotelId): {
        return (
          <ProfitRoomForm
            state={state}
            handleCheckInChange={handleCheckInChange}
            handleCheckOutChange={handleCheckOutChange}
            handleOpen={handleOpen}
            hotelName={profitRoomHotelId}
          />
        )
      }

      case Boolean(bookDirectHotelId): {
        return (
          <BookDirectForm
            state={state}
            hotelCode={bookDirectHotelId}
            handleCheckInChange={handleCheckInChange}
            handleCheckOutChange={handleCheckOutChange}
            handleOpen={handleOpen}
            today={today}
          />
        );
      }

      default:
        return (
          <EtchForm
            state={state}
            setState={setState}
            venueName={venueName}
            hotelCode={etchHotelCode}
            handleCheckInChange={handleCheckInChange}
            handleCheckOutChange={handleCheckOutChange}
            handleOpen={handleOpen}
            today={today}
          />
        );

    }
  };

  return (
    <>
      {content && <Wrapper dangerouslySetInnerHTML={{ __html: content }} />}
      <Wrapper>{formMarkup()}</Wrapper>
    </>
  );
};

export default BookRoom;
