import React, { createContext, useEffect, useReducer, useCallback } from "react";

import { titleCase, getParams, isEnquiry, rIC, sendAnalytics } from "../helper";
import { format, getDay, parse } from "date-fns";

const initialState = (params = {}, steps = ["start"], venueId) => {

  const {
    booking_type: initialBookingType,
    booking_type_category: bookingTypeCategory,
    date = "",
    first_name: firstName = "",
    last_name: lastName = "",
    time = "",
    num_people: numPeople,
    reference = "",
    status,
    type,
  } = params;

  let bookingTypeTitle = null;

  if (type) {
    const { name } = JSON.parse(type) || {};

    if (name) {
      bookingTypeTitle = name;
    }
  }

  const getStep = () => {
    if (reference.includes("DMN-")) {
      return "confirmed";
    }

    return steps[0];
  }

  const step = getStep();
  const stepIndex = steps.findIndex((currentStep) => currentStep === step);

  return {
    apiEnabled: true,
    availableOffers: [],
    book: null,
    bookablePackages: null,
    bookingType: null,
    bookingTypeCategory,
    bookingTypeTitle,
    check: status ? { result: status } : null,
    comments: "",
    date,
    dob: "",
    email: "",
    errors: false,
    firstName,
    initialBookingType,
    lastName,
    loading: false,
    numPeople,
    offer: null,
    overrides: [],
    package: null,
    packages: null,
    phone: "",
    policy: false,
    promo: false,
    rules: {},
    step,
    stepCache: {},
    stepIndex,
    steps,
    time,
    timeslots: [],
    venueId: venueId !== "corporate" ? venueId : undefined,
  };
};

const params = getParams(window.location.search);

const tableStore = createContext(initialState(params));
const { Provider } = tableStore;

const TableProvider = ({ children, venueId, endpoint, isOnPage, isActive, panelType }) => {

  let initialSteps = [venueId === "corporate" && "venue-select", "start", "details", "confirmed"].filter(Boolean);
  if (isOnPage) {
    initialSteps = ["start", "bookingDetails", "packages", "details", "confirmed"];
  }

  const [state, dispatch] = useReducer((state, actions) => (!Array.isArray(actions) ? [actions] : actions).reduce((currentState, action) => {
    switch (action.type) {
      case "SET_API_ENABLED":
        return {
          ...currentState,
          apiEnabled: action.data
        };
      case "SET_BOOKING_TYPE":
        return {
          ...currentState,
          bookingType: action.data.id,
          bookingTypeTitle: action.data.title,
          crossSellEnabled: action.data.crossSellEnabled,
          crossSellTypes: action.data.crossSellTypes,
        };
      case "SET_PACKAGES":
        return {
          ...currentState,
          packages: action.data,
        };
      case "SET_BOOKABLE_PACKAGES": {
        const hoursUntilBooking = (Date.parse(`${currentState.date}`) - Date.now()) / 1000 / 60 / 60;

        const filteredPackages = Array.from(currentState.packages, (p) => {
          if (p.minimumNoticePeriod <= hoursUntilBooking) {
            return p;
          }
          return false;
        }).filter(Boolean);

        return {
          ...currentState,
          bookablePackages: filteredPackages,
        };
      }
      case "SET_DATE":
        return {
          ...currentState,
          date: action.data,
        };
      case "SET_TIME":
        return {
          ...currentState,
          time: action.data,
        };
      case "SET_GUESTS":
        return {
          ...currentState,
          numPeople: action.data,
        };
      case "SET_PACKAGE":
        return {
          ...currentState,
          package: action.data,
        };
      case "SET_EMAIL":
        return {
          ...currentState,
          email: action.data,
        };
      case "SET_PHONE":
        return {
          ...currentState,
          phone: action.data,
        };
      case "SET_FIRST_NAME":
        return {
          ...state,
          firstName: action.data,
        };
      case "SET_LAST_NAME":
        return {
          ...state,
          lastName: action.data,
        };
      case "SET_DOB":
        return {
          ...state,
          dob: action.data,
        };
      case "SET_PROMO":
        return {
          ...state,
          promo: action.data,
        };
      case "SET_POLICY":
        return {
          ...currentState,
          policy: action.data,
        };
      case "SET_COMMENTS":
        return {
          ...currentState,
          comments: action.data,
        };

      case "SET_ERROR":
        return {
          ...currentState,
          errors: action.data,
          loading: false,
        };
      case "SET_LOADING":
        return {
          ...currentState,
          errors: false,
          loading: action.data,
        };
      case "SET_CHECK":
        return {
          ...currentState,
          check: action.data,
          loading: false,
        };

      case "SET_BOOK":
        return {
          ...currentState,
          book: action.data,
          loading: false,
        };
      case "SET_STEP":
        return {
          ...currentState,
          step: action.data,
          loading: false,
        };
      case "SET_STEP_INDEX":
        return {
          ...currentState,
          stepIndex: action.data,
          loading: false,
        };
      case "SET_RULES":
        return {
          ...currentState,
          rules: action.data,
        };
      case "SET_MANDATORY_PACKAGES":
        return {
          ...currentState,
          packageMandatory: action.data,
        };
      case "RESET":
        return {
          ...initialState(params, initialSteps, venueId),
        };
      case "SET_DURATION":
        return {
          ...currentState,
          duration: action.data,
        };
      case "SET_MAX_DURATION":
        return {
          ...currentState,
          maxDuration: action.data,
        };
      case "SET_MIN_DURATION":
        return {
          ...currentState,
          minDuration: action.data,
        };
      case "SET_TIMESLOTS":
        return {
          ...currentState,
          timeslots: action.data,
        };
      case "SET_END_TIMESLOTS":
        return {
          ...currentState,
          endTimeslots: action.data,
        };
      case "SET_OVERRIDES":
        return {
          ...currentState,
          overrides: action.data,
        };
      case "SET_VENUE_ID":
        return {
          ...currentState,
          venueId: action.data,
        };
      case "SET_STEP_CACHE":
        return {
          ...currentState,
          stepCache: {
            ...currentState.stepCache,
            ...action.data
          }
        }
      case "SET_AVAILABLE_OFFERS":
        return {
          ...currentState,
          availableOffers: action.data
        }
      case "SET_OFFER":
        return {
          ...currentState,
          offer: action.data
        }
      case "SET_ADDITIONAL_STEPS": {
        const { availableOffers, bookablePackages } = currentState;

        const bookingDay = format(
          parse(currentState.date, "yyyy-MM-dd", new Date()), "EEEE"
        );

        const dayPackages = bookablePackages.filter((item) => {
          if (!item.availableDays) return true;
          return item.availableDays.includes(bookingDay);
        });

        const additionalSteps = [
          availableOffers.length > 0 && "offers",
          dayPackages.length > 0 && "packages"
        ].filter(Boolean);

        const startIndex = initialSteps.findIndex((step) => step === "start");

        if (additionalSteps.length > 0 && startIndex >= 0) {
          return {
            ...currentState,
            steps: [
              ...initialSteps.slice(0, startIndex + 1),
              ...additionalSteps,
              ...initialSteps.slice(startIndex + 1)
            ]
          }
        }

        return currentState;
      }

      case "NEXT_STEP": {
        const { bookablePackages } = currentState;
        const nextStepIndex = currentState.stepIndex + 1;
        const nextStep = currentState.steps[nextStepIndex];

        const bookingDay = format(
          parse(currentState.date, "yyyy-MM-dd", new Date()), "EEEE"
        );

        const dayPackages = bookablePackages.filter((item) => {
          if (!item.availableDays) return true;
          return item.availableDays.includes(bookingDay);
        });

        if (
          nextStep === "packages" &&
          dayPackages.length < 1
        ) {
          return {
            ...currentState,
            step: currentState.steps[currentState.stepIndex + 2],
            stepIndex: currentState.stepIndex + 2,
          }
        }

        return {
          ...currentState,
          step: nextStep,
          stepIndex: nextStepIndex,
        }
      }
      case "PACKAGES_OR_DETAILS_STEP": {
        const { bookablePackages } = currentState;

        return {
          ...currentState,
          step: bookablePackages.length ? 'packages' : 'details',
          loading: false,
        }
      }
      case "PREVIOUS_STEP": {
        const previousStepIndex = currentState.stepIndex - 1;
        const previousStep = currentState.steps[previousStepIndex];

        return {
          ...currentState,
          step: previousStep,
          stepIndex: previousStepIndex,
        }
      }
      case "SET_CROSS_SELL_VISIBLE": {
        return {
          ...currentState,
          isCrossSellVisible: action.data
        }
      }
      default:
        break;
    }

    return currentState;
  },
    state
  ),
    initialState(params, initialSteps, venueId)
  );

  const checkAvailability = () => {
    dispatch({ type: "SET_LOADING", data: true });

    fetch(`${endpoint}/v1/venue/${state.venueId}/check`, {
      body: JSON.stringify({
        date: state.date,
        duration: state.duration,
        time: state.time,
        bookingType: state.bookingType,
        numPeople: state.numPeople,
        package: state.package,
      }),
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        const errors = Array.from(
          Object.values(data._validationResults),
          (field) => {
            return field.errors;
          }
        )
          .filter(Boolean)
          .flat()
          .map((a) => a.message);

        if (errors.length) {
          [...new Set(errors)].forEach((e) => {
            sendAnalytics({
              hitType: "event",
              eventAction: "Get Availability",
              eventLabel: `Error - ${e}`,
            });
          });
          return dispatch({
            type: "SET_ERROR",
            data: [...new Set(errors)],
          });
        }


        return dispatch([
          { type: "SET_BOOKABLE_PACKAGES" },
          { type: "SET_ADDITIONAL_STEPS" },
          { type: "SET_CHECK", data },
          { type: "NEXT_STEP" },
          { type: "SET_LOADING", data: false }
        ]);
      });
  };

  const completeBooking = () => {
    dispatch({ type: "SET_LOADING", data: true });

    fetch(`${endpoint}/v1/venue/${state.venueId}/book`, {
      body: JSON.stringify({
        date: state.date,
        time: state.time,
        bookingType: state.bookingType,
        numPeople: state.numPeople,
        offer: state?.offer?.id || null,
        package: state.package,
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        phone: state.phone,
        dob: state.dob,
        policy: state.policy,
        promo: state.promo,
        comments: state.comments,
        duration: state.duration,
      }),
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: "SET_BOOK",
          data,
        });

        if (data?.error) {
          sendAnalytics({
            hitType: "event",
            eventAction: isEnquiry(state.check) ? "Enquire" : "Book",
            eventLabel: `Error - ${data.message}`,
          });

          return dispatch({
            type: "SET_ERROR",
            data: [data.message],
          });
        }

        if (data?.result === "confirmed" || data?.result === "received") {

          if (isOnPage) {
            dispatch({
              type: "SET_STEP",
              data: "confirmed"
            });
          } else {
            dispatch({
              type: "NEXT_STEP"
            });
          }

        }
      });
  };

  const reset = () => {
    return dispatch({ type: "RESET" });
  };

  const sendAnalyticsData = useCallback(
    /** @param { { hitType: "pageview"; page: string; title: string } | { hitType: 'event'; eventAction: string; eventLabel?: string } } payload */
    (payload) => {
      if (!isActive) {
        // Initial step is `confirmed` when coming from DMN booking redirect
        // we want to log analytics
        if (state.step !== "confirmed") return;
      }

      rIC(() => {
        const data = { ...payload };

        if (data.hitType === "pageview") {
          data.page = `${titleCase(state.step)}/${data.page}`;
        }

        sendAnalytics(data);
      });
    },
    [isActive, state.step]
  );

  useEffect(() => {
    if (!isActive) return;
    sendAnalytics({
      hitType: "pageview",
      page: titleCase(state.step),
      title: state?.bookingTypeTitle || "Booking/Enquiry",
    });
  }, [isActive, state.step]);

  return (
    <Provider
      value={{
        isActive,
        panelType,
        table: state,
        tableDispatch: dispatch,
        setup: { venueId, endpoint },
        actions: {
          checkAvailability,
          completeBooking,
          reset,
          sendAnalyticsData,
        },
      }}
    >
      {children}
    </Provider>
  );
};

export { tableStore, TableProvider };
