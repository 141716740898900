import styled from "styled-components";

const Form = styled.form`
    width: 100%;
    max-width: 422px;
    margin-bottom: 20px;
    opacity: ${props => props.isLoading ? 0.5 : 1};
    transition: 0.3s opacity ease-in-out;
    pointer-events: ${props => props.isLoading ? "none" : "auto"};

    > div {
        display: flex;
        align-items: flex-end;
    }

    button {
        flex-shrink: 0;
        width: 112px;
        height: 45px;
    }
`;

export default Form;
