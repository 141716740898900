export const dateFormat = (dateStr) => {
  if (!dateStr) return "";
  const dArr = dateStr.split("-");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    `${dArr[2]} ${months[parseInt(dArr[1]) - 1]} ${dArr[0].substring(2)}`
  );
};

export function isEnquiry(check) {
  if (typeof check === "object" && check !== null && ["enquire", "received"].includes(check?.result)) {
    return true;
  }

  return false;
}

export function getParams(url = "") {
  const params = {};

  const [, search] = url.split("?", 2);

  if (search) {
    const paramStrings = search.split('&');

    for (let i = 0; i < paramStrings.length; i += 1) {
      const [key, value] = paramStrings[i].split('=', 2);
      params[key] = decodeURIComponent(value);
    }
  }

  return params;
}

export const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export function sortByLabel(a, b) {
  return a.label.localeCompare(b.label);
}

export function sortBySortOrder(a, b) {
  return a.sortOrder - b.sortOrder;
}

export function titleCase(string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export const rIC =
  "requestIdleCallback" in window ? requestIdleCallback : setTimeout;

// Tracking

/**
 * Sends data to GA tracker(s)
 * @param { { hitType: "pageview"; page: string; title: string } | { hitType: 'event'; eventCategory: string; eventAction: string; eventLabel?: string } } payload
 */
export function sendAnalytics(payload = {}) {
  const data = { ...payload };
  // Modify payload
  if (typeof window.dataLayer !== "undefined") {
    if (data.hitType === "pageview") {
      window.dataLayer.push({
        event: "ga.pageview",
        page: `/DMN/${data.page}`,
        title: data.title
      });
    } else if (data.hitType === "event") {
      window.dataLayer.push({
        event: "ga.event",
        category: data.eventCategory || "Booking",
        action: data.eventAction,
        label: data.eventLabel
      });
    }
  }
}

export function getManadatoryPackagesFromAPI(item) {
  const updatedItem = {
    packageMandatorySunday: typeof item.packageMandatorySunday === "boolean" ? item.packageMandatorySunday : item.parent.packageMandatorySunday,
    packageMandatoryMonday: typeof item.packageMandatoryMonday === "boolean" ? item.packageMandatoryMonday : item.parent.packageMandatoryMonday,
    packageMandatoryTuesday: typeof item.packageMandatoryTuesday === "boolean" ? item.packageMandatoryTuesday : item.parent.packageMandatoryTuesday,
    packageMandatoryWednesday: typeof item.packageMandatoryWednesday === "boolean" ? item.packageMandatoryWednesday : item.parent.packageMandatoryWednesday,
    packageMandatoryThursday: typeof item.packageMandatoryThursday === "boolean" ? item.packageMandatoryThursday : item.parent.packageMandatoryThursday,
    packageMandatoryFriday: typeof item.packageMandatoryFriday === "boolean" ? item.packageMandatoryFriday : item.parent.packageMandatoryFriday,
    packageMandatorySaturday: typeof item.packageMandatorySaturday === "boolean" ? item.packageMandatorySaturday : item.parent.packageMandatorySaturday,
  }

  return weekdays.reduce((previousWeekdays, weekday) => ([...previousWeekdays, updatedItem[`packageMandatory${titleCase(weekday)}`] ? { label: titleCase(weekday), value: weekday } : false]), []).filter(Boolean);
}

export function getManadatoryPackagesForAPI(selectedWeekdayOptions) {
  const selectedWeekdays = selectedWeekdayOptions.map((option) => option.value);
  return weekdays.reduce((previousWeekdays, weekday) => ({ ...previousWeekdays, [`packageMandatory${titleCase(weekday)}`]: selectedWeekdays.includes(weekday) }), {});
}

export function sendAnalyticsGA4(data = {}, eventGa4 = null, category = null, bookingNew = null) {
  const table = { ...data };
  const [h, m] = table?.time.split(":");
  const totalHours = Number(h) + Math.floor(table?.duration / 60);
  const totalMinutes = Number(m) + (Number(table?.duration) % 60);
  const endTime = totalHours.toString().padStart(2, "0") + ":" + totalMinutes.toString().padStart(2, "0");

  const dataGA = {
    venue_id: table?.venueId,
    booking_category: category ? category : null,
    booking_type: table?.bookingTypeTitle,
    date: table?.date,
    guests: table?.numPeople,
    start_time: table?.time,
    end_time: endTime,
    event: eventGa4,
  };

  if (table.stepCache.venueNameSelect) {
    dataGA["venue_name"] = table.stepCache.venueNameSelect;
  }

  if (eventGa4 === 'table_enquiry') {
    dataGA["gtm_tag_name"] = 'GA4 - Table booking - enquiry';
  } else if (eventGa4 === 'booking_form_enquiry') {
    dataGA["gtm_tag_name"] = 'GA4 - Booking Form Enquiry';
  } else if (eventGa4 === 'booking_form_confirm') {
    dataGA["gtm_tag_name"] = 'GA4 - Booking Form Confirm';
  } else {
    dataGA["gtm_tag_name"] = 'GA4 - Table booking - confirmation';
    dataGA["viewed_offer"] = table?.offer;
    dataGA["package"] = table?.package;
    dataGA["button_location"] = "Modal";
  }

  if (bookingNew) {
    if (document.getElementById("on-page-booking")) {
      const onPageBooking = document.getElementById("on-page-booking");
      dataGA["form_name"] = onPageBooking.getAttribute('data-title') ? onPageBooking.getAttribute('data-title') : null;
      dataGA["conversion_id"] = onPageBooking.getAttribute('data-conversion_id') ? onPageBooking.getAttribute('data-conversion_id') : null;
      dataGA["label"] = onPageBooking.getAttribute('data-label') ? onPageBooking.getAttribute('data-label') : null;
    }
  }

  window.dataLayer.push(dataGA);
  return true;
}

export function sendGA4New(eventGa4 = null, bookingType = null, data = null) {
  const dataGA = {
    event: eventGa4,
    booking_type: bookingType,
  };

  if (document.getElementById("on-page-booking")) {
    const onPageBooking = document.getElementById("on-page-booking");
    dataGA["form_name"] = onPageBooking.getAttribute('data-title') ? onPageBooking.getAttribute('data-title') : null;
    dataGA["conversion_id"] = onPageBooking.getAttribute('data-conversion_id') ? onPageBooking.getAttribute('data-conversion_id') : null;
    dataGA["label"] = onPageBooking.getAttribute('data-label') ? onPageBooking.getAttribute('data-label') : null;
  }

  if (data) {
    const tableNew = { ...data };
    const [h, m] = tableNew?.time.split(":");
    const totalHours = Number(h) + Math.floor(tableNew?.duration / 60);
    const totalMinutes = Number(m) + (Number(tableNew?.duration) % 60);
    const endTime = totalHours.toString().padStart(2, "0") + ":" + totalMinutes.toString().padStart(2, "0");

    // dataGA["booking_category"] = bookingType;
    dataGA["booking_type"] = tableNew?.bookingTypeTitle;
    dataGA["date"] = tableNew?.date;
    dataGA["guests"] = tableNew?.numPeople;
    dataGA["start_time"] = tableNew?.time;
    dataGA["end_time"] = endTime;

    if (tableNew.stepCache.venueNameSelect) {
      dataGA["venue_name"] = tableNew.stepCache.venueNameSelect;
    }
  }

  window.dataLayer.push(dataGA);
  return true;
}