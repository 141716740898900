import styled from "styled-components";

const CloseButton = styled.button`
  position: absolute;
  right: 3px;
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  opacity: 0.8;
  padding: 0;
  background: 0;
  border: 1px solid #000;
  border-radius: 50%;

  &:hover {
    opacity: 1;
  }

  > span {
    position: relative;
    display: block;
    width: 13px;
    height: 13px;
  }

  > span:before,
  > span:after {
    position: absolute;
    left: 6px;
    content: " ";
    height: 12px;
    width: 1px;
    top: 0;
    background-color: #000;
  }

  > span:before {
    transform: rotate(45deg);
  }

  > span:after {
    transform: rotate(-45deg);
  }
`;

export default CloseButton;
