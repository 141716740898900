import React, { useContext } from "react";
import styled from "styled-components";

import { dateFormat } from "../helper";
import Grid from "./ui/Grid";
import SummaryItem from "./ui/SummaryItem";
import { tableStore } from "../store/table";

const Wrapper = styled.div`
  background: #5f5f5f;
  padding: 20px 30px;
  color: white;

  .theme--light & {
    margin-right: 30px;
    margin-left: 30px;
    padding-right: 0;
    padding-left: 0;
    background: white;
    border-bottom: 1px solid rgba(112, 112, 112, 0.22);;
    color: black;
  }
`;

const Title = styled.h3`
  color: white;
  font-size: 14px;
  margin: 0;
  text-transform: uppercase;

  .theme--light & {
    color: black;
  }
`;

const Summary = () => {
  const { table } = useContext(tableStore);

  const activePackage = table.packages
    ? Object.values(table.packages)
        .filter((item) => {
          return item.id === table.package;
        })
        .filter(Boolean)
        .shift()
    : null;

  return (
    <Wrapper>
      <Title>Summary</Title>
      <Grid>
        <SummaryItem label="Date" value={dateFormat(table.date)} />
        <SummaryItem label="Time" value={table.time} />
        <SummaryItem label="Guests" value={table.numPeople} />
        <SummaryItem
          label="Booking Type"
          value={table?.bookingTypeTitle}
        />
      </Grid>
      {table.step !== "offers" && table.offer && (
        <Grid mobileMargin="20px -10px 0">
          <SummaryItem
            label="Offer Applied"
            popup={(table.offer.popupText !== null || table.offer.description !== null) && table.offer}
            value={table.offer.title}
          />
        </Grid>
      )}
      {table.step !== "packages" && activePackage && (
        <Grid mobileMargin={table.offer ? "0 -10px" : "20px -10px 0"}>
          <SummaryItem
            label="Package Selected"
            popup={(activePackage.popupText !== null || activePackage.description !== null) && activePackage}
            value={activePackage.title}
          />
        </Grid>
      )}
    </Wrapper>
  );
};

export default Summary;
