function getFriendlyRoundelText(numberOfOffers) {
    return `View Offer${numberOfOffers > 1 ? "s" : ""}`;
}

function getOffersRoundel(bookingTypeId, offers) {
    const allowedOffers = offers.reduce((previousOffers, offer) => {
        const { bookingTypes, restrictOfferToBookingTypes } = offer;

        if (restrictOfferToBookingTypes && !bookingTypes.includes(bookingTypeId) || (!offer.popupText && !offer.description)) {
            return previousOffers;
        }

        return [...previousOffers, offer];
    }, []);

    if (allowedOffers.length > 0) {
        return {
            popup: allowedOffers,
            text: getFriendlyRoundelText(allowedOffers.length)
        }
    }

    return undefined;
}

export default getOffersRoundel;
