import styled from "styled-components";

const Divider = styled.hr`
  display: block;
  height: 1px;
  margin: 30px 0;
  background-color: #707070;
  opacity: 0.22;
`;

export default Divider;
