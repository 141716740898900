import React, { createRef, useCallback, useContext, useEffect, useState } from "react";
import { scrollIntoView } from 'scroll-js';

import ButtonGroup from "../ui/ButtonGroup";
import Divider from "../ui/Divider";
import formatVenueAddress from "../../utils/formatVenueAddress";
import formatVenueArea from "./utils/formatVenueArea";
import GridWrap from "../ui/GridWrap";
import * as StyledVenueSelect from "./styled";
import { tableStore } from "../../store/table";
import useGooglePlaces from "./hooks/useGooglePlaces";
import Venue from "./Venue/Venue";

import "./VenueSelect.css";

const VenueSelect = ({ modalRef }) => {
  const continueRef = createRef();

  const {
    setup: { endpoint },
    table: { stepCache: { "venue-select": cache = { venues: [] } }, venueId },
    tableDispatch
  } = useContext(tableStore);

  const [state, setState] = useState({ error: "", isLoading: false, venues: cache.venues });

  const { coords, ref } = useGooglePlaces();

  const handleContinue = useCallback(() => tableDispatch([
    { type: "NEXT_STEP" },
    { type: "SET_STEP_CACHE", data: { "venue-select": { venues: state.venues } } }
  ]), [state.venues]);


  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const lat = formData.get("lat");
    const lng = formData.get("lng");

    if (!lat || !lng) {
      setState((previousState) => ({ ...previousState, error: "Please select a location from the dropdown." }));
      return;
    }

    try {
      setState((previousState) => ({
        ...previousState,
        error: "",
        isLoading: true,
      }));

      const response = await fetch(`${endpoint}/v1/venues/closest?latitude=${lat}&longitude=${lng}`,
        { headers: { "Content-Type": "application/json" } }
      );

      if (!response.ok || response.status !== 200) {
        throw new Error;
      }

      const venues = await response.json();

      if (Array.isArray(venues) && setState) {
        setState((previousState) => ({
          ...previousState,
          isLoading: false,
          venues: venues.slice(0, 3)
        }));
      }
    } catch (_error) {
      setState((previousState) => ({
        ...previousState,
        error: "An error has occurred. Please try again later.",
        isLoading: false
      }));
    }
  }

  const handleVenueSelect = (id) => tableDispatch({ type: "SET_VENUE_ID", data: id });

  useEffect(() => {
    if (state.error !== "") {
      setState((previousState) => ({ ...previousState, error: "" }));
    }
  }, [coords])

  useEffect(() => {
    if (continueRef.current && modalRef && modalRef.current && venueId) {
      scrollIntoView(continueRef.current, modalRef.current, { behavior: "smooth" });
    }
  }, [venueId])

  return (
    <>
      <StyledVenueSelect.Title textTransform="uppercase">
        <strong>Find Your Venue</strong>
      </StyledVenueSelect.Title>
      <StyledVenueSelect.Form isLoading={state.isLoading} onSubmit={handleSubmit}>
        <div>
          <StyledVenueSelect.FormField htmlFor="search-input">
            <span>Location</span>
            <input className="js-venue-select-input" id="search-input" ref={ref} />
          </StyledVenueSelect.FormField>
          <input name="lat" type="hidden" value={coords.lat || ""} />
          <input name="lng" type="hidden" value={coords.lng || ""} />
          <StyledVenueSelect.Button type="submit">Search</StyledVenueSelect.Button>
        </div>
        {state.error && <StyledVenueSelect.Error>{state.error}</StyledVenueSelect.Error>}
      </StyledVenueSelect.Form>
      {state.venues.length > 0 && (
        <>
          <StyledVenueSelect.Title as="h2">Your Nearest Venues</StyledVenueSelect.Title>
          <GridWrap marginBottom="-20px">
            {state.venues.map((venue) => {
              const {
                areas,
                buildingName,
                city,
                distance,
                id,
                postCode,
                street,
                title,
                venuePickerImageUrl
              } = venue;

              return (
                <Venue
                  active={id === venueId}
                  address={formatVenueAddress({ buildingName, city, postCode, street })}
                  area={formatVenueArea(areas)}
                  distance={distance}
                  id={id}
                  imageUrl={venuePickerImageUrl}
                  key={id}
                  onSelect={handleVenueSelect}
                  title={title}
                />
              )
            })}
          </GridWrap>
        </>
      )}
      <Divider />
      <ButtonGroup ref={continueRef}>
        <StyledVenueSelect.Button
          disabled={typeof venueId === "undefined"}
          onClick={handleContinue}
        >
          Continue
        </StyledVenueSelect.Button>
      </ButtonGroup>
    </>
  );
}

export default VenueSelect;
