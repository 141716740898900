import styled from "styled-components";
import getFont from "../../../utils/getFont";


const Title = styled.h1`
    margin-bottom: 20px;
    font-family: ${getFont()};
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 2.1rem;
    color: #000;
    text-transform: ${props => props.textTransform || "none"};
    letter-spacing: 0.8px;

    > strong {
        font-weight: bold;
    }
`;

export default Title;
