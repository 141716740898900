import styled from "styled-components";

const Text = styled.p`
  display: block;
  margin: 30px 0;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: white;

  .theme--light & {
    color: black;
  }
`;

export default Text;
