import React, { useContext, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { tableStore } from "../store/table";
import Back from "./ui/Back";
import Button from "./ui/Button";
import Text from "./ui/Text";
import Errors from "./ui/Errors";
import ButtonGroup from "./ui/ButtonGroup";

const Wrapper = styled.div`
  max-width: 438px;
  margin: 0 auto;
`;

const TimeSlots = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 -10px;

  & > * {
    margin: 10px;
  }
`;

const AlternateTimes = () => {
  const { table, tableDispatch, setup, actions } = useContext(tableStore);
  const [timeSlots, setTimeSlots] = useState([]);

  const load = useCallback(() => {
    fetch(
      `${setup.endpoint}/v1/venue/${setup.venueId}/timeslots?date=${table.date}&bookingType=${table.bookingType}&numPeople=${table.numPeople}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.timeslots) {
          setTimeSlots(data.timeslots);
        } else {
          setTimeSlots([]);
        }
      });
  }, [setup.endpoint, setup.venueId, table]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Back
        onClick={() => {
          tableDispatch({
            type: "SET_PACKAGE",
            data: null,
          });
          tableDispatch({
            type: "SET_CHECK",
            data: null,
          });
        }}
      >
        Back
      </Back>
      <Wrapper>
        <Text>
          Unfortunately there is no availability 2 hours either side of your requested time. Please select a new time or date for your booking.
        </Text>
        {timeSlots.length > 0 && (
          <TimeSlots>
            {Array.from(timeSlots, (time) => (
              <Button
                active={table.time === time}
                onClick={() => {
                  tableDispatch({
                    type: "SET_TIME",
                    data: time,
                  });
                }}
              >
                {time}
              </Button>
            ))}
          </TimeSlots>
        )}
      </Wrapper>
      <Errors errors={table.errors} />
      {timeSlots.length > 0 && (
        <ButtonGroup>
          <Button
            active
            disabled={table.loading}
            onClick={() => actions.checkAvailability()}
          >
            Check Availability
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

export default AlternateTimes;
