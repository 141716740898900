import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: ${props => props.paddingRight || 0};
  overflow: hidden;

  & > * {
    flex: 1;
  }
`;

export default Flex;
