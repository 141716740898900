import styled from "styled-components";
import getFont from "../../../../utils/getFont";
const Text = styled.p`
  margin-bottom: 16px;
  font-family: ${getFont()};
  font-size: 1.6rem;
  font-weight: ${props => props.fontWeight || 300};
  color: black;
  line-height: 2.1rem;
`;

export default Text;
