import styled from "styled-components";
import getFont from "../../utils/getFont";

const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 10px 2px;
  position: relative;
  margin-bottom: 15px;
  min-height: 56px;

  .theme--light & {
    border: 1px solid black;
  }

  & input,
  & select {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    border: none;
    background: transparent;
    font-family: ${getFont()};
    padding: 0 10px;
  }

  & input[type="text"],
  & input[type="email"] {
    opacity: 1;
    font-size: 14px;
    line-height: 29px;
    letter-spacing: 0.03em;
    padding-top: 22px;

    &:focus + span {
      height: 29px;
    }
  }

  & input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

  & input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
  }

  & input[type="date"]::-webkit-clear-button {
    z-index: 1;
  }
`;

const Label = styled.label`
  opacity: 0.44;
  font-size: 14px;
  line-height: 20px;
`;

const Value = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 29px;
  letter-spacing: 0.03em;
  opacity: ${(props) => (props.hideValue ? "0" : "1")};
`;

const FieldLarge = ({ children, label, value, hideValue }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      {children}
      <Value hideValue={hideValue}>
        <span>{value}</span>
      </Value>
    </Wrapper>
  );
};

export default FieldLarge;
