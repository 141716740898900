import { isWithinInterval } from "date-fns";
import React, { useEffect, useState } from "react";

import Field from "./Field";
import { weekdays } from "../../helper";

function getOptions(date, overrides, rules) {
    if (date) {
        for (let i = 0; i < overrides.length; i += 1) {
            const { available, dateFrom, dateTo, maxPeople, minPeople } = overrides[i];

            if (available && isWithinInterval(new Date(`${date}T00:00:00`), { start: new Date(`${dateFrom}T00:00:00`), end: new Date(`${dateTo}T23:59:59`) })) {
                return [...Array(maxPeople - (minPeople - 1))].map((_, index) => ({ label: index + minPeople, value: index + minPeople }));
            }
        }

        if (Object.keys(rules).length > 0) {
            try {
                const weekday = weekdays[new Date(date).getDay()];
                const weekdayRules = rules[weekday];

                const { minPeople, maxPeople } = weekdayRules;

                return [...Array(maxPeople - (minPeople - 1))].map((_, index) => ({ label: index + minPeople, value: index + minPeople }));
            } catch (_) {
                throw new Error("[Propeller DMN] - Unable to get guest field options.");
            }
        }
    }

    return [];
}

const GuestField = ({ date, overrides, rules, handleGuestsChange, value }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const newOptions = getOptions(date, overrides, rules);

        if (newOptions.length && !newOptions.some((option) => `${option.value}` === `${value}`)) {
            const hasTwo = newOptions.some((option) => `${option.value}` === "2");
            handleGuestsChange({ guests:hasTwo ? 2 : newOptions[0].value, nonInterraction: true })
        }

        setOptions(newOptions);
    }, [date, rules]);

    return (
      <Field label="Guests" value={!options.length ? "" : value}>
        <select
          disabled={!options.length}
          value={value}
          onChange={(e) => handleGuestsChange({ guests: e.target.value })}
        >
          {options.map((option) => <option key={`guest-field-option-${option.value}`} value={option.value}>{option.label}</option>)}
        </select>
      </Field>
    )
}

export default GuestField;
