import styled from "styled-components";

const Button = styled.button`
  font-size: 16px;
  border: 1px solid;
  color: white;
  padding: 1rem 2rem;
  text-transform: uppercase;
  background: ${props => props.active ? "var(--colour-primary-dmn)" : "transparent"};
  border-color: ${props => props.active ? "var(--colour-primary-dmn)" : "white"};
  opacity: ${props => props.active && !props.disabled ? "1" : "0.6"};

  .theme--light & {
    color: ${props => props.active ? "white" : "black"};
    border-color: ${props => props.active ? "var(--colour-primary-dmn)" : "#707070"};
    border-radius: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover:disabled {
    background: ${props => props.active ? "var(--colour-primary-dmn)" : "transparent"};
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export default Button;
