import React, { useState } from "react";
import styled from "styled-components";

import Popup from "../Popup/Popup";
import PopupTrigger from "../Popup/PopupTrigger/PopupTrigger";
import PopupView from "../Popup/PopupView/PopupView";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px 2px;
  position: relative;
`;

const Label = styled.h5`
  opacity: 0.6;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  font-weight: normal;

  .theme--light & {
    color: #707070;
    opacity: 1;
  }
`;

const Value = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.03em;
  font-weight: bold;
`;

const SummaryItem = ({ label, popup, value }) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const popupTrigger = <PopupTrigger onClick={() => setPopupOpen(true)}>i</PopupTrigger>;

  if (!value) {
    return null;
  }
  return (
    <>
      <Wrapper>
        <Label>{label}</Label>
        <Value>
          {value}
          {popup && popupTrigger}
        </Value>
      </Wrapper>
      {popupOpen && (
        <Popup handleClose={() => setPopupOpen(false)}>
          <PopupView data={popup} hideButton />
        </Popup>
      )}
    </>
  );
};

export default SummaryItem;
