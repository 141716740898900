/* eslint-disable react/no-danger */
import DOMPurify from "dompurify";
import React from "react";

import Slide from "../../ui/Slide";
import * as StyledPopupView from "./styled";

const PopupView = ({ active = false, click, data, hideButton = false }) => {
  const {
    title,
    description,
    desktopImageUrl1,
    popupImageUrl,
    popupText,
    popupTitle,
  } = data;

  const firstImgUrl = desktopImageUrl1 !== null ? desktopImageUrl1 : "https://youngscorporate-legacy.standard.aws.prop.cm/youngs/uploads/sites/2/2021/07/square.jpg";

  return (
    <StyledPopupView.Wrapper>
      <Slide paddingBottom="56.25%">
        <img alt={title} style={{ width: '100%' }} src={popupImageUrl || firstImgUrl} />
      </Slide>
      <h3>{popupTitle || title}</h3>
      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(popupText || description) }} />
      {!hideButton && (
        <StyledPopupView.Button active={active} onClick={() => click(data)}>
          {active ? "Selected" : "Select"}
        </StyledPopupView.Button>
      )}
    </StyledPopupView.Wrapper>
  )
}

export default PopupView;
