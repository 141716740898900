import styled from "styled-components";

const Back = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  cursor: pointer;

  .theme--light & {
    color: black;
  }

  &:before {
    content: "‹";
    margin-top: -3px;
    margin-right: 5px;
    font-size: 22px;
    font-weight: normal;
    line-height: 13px;
  }

  &:hover span {
    text-decoration: underline;
  }
`;

export default Back;
