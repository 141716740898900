import styled from "styled-components";

const Slide = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${props => props.paddingBottom || "100%"};
  display: flex;
  justifty-content: center;
  align-items: cetner;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

export default Slide;
