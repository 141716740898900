import { format, getDay, parse } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { tableStore } from "../store/table";
import Back from "./ui/Back";
import Button from "./ui/Button";
import ButtonGroup from "./ui/ButtonGroup";
import GridWrap from "./ui/GridWrap";
import Package from "./ui/Package";
import Text from "./ui/Text";
import { weekdays } from "../helper";
import { useMemo } from "react";

const Copy = styled(Text)`
  max-width: 525px;
  margin-top: 12px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: normal;

  strong {
    font-weight: bold;
  }
`;

const Intro = styled(Text)`
  font-weight: normal;
  margin-bottom: 10px;
  font-size: 16px;
`;

const Description = styled(Text)`
  font-weight: normal;
  margin-top: 10px;
  opacity: 0.6;
  font-size: 16px;
  line-height: 24px;
`;

const Packages = () => {
  const { actions, table, tableDispatch } = useContext(tableStore);

  const handleBack = () => tableDispatch([
    { type: "PREVIOUS_STEP" },
    { type: "SET_PACKAGE", data: null }
  ]);
  const handleNextStep = () => tableDispatch({ type: "NEXT_STEP" });

  const [isMandatory] = useState(table.packageMandatory[weekdays[getDay(new Date(table.date))]]);

  const setPackage = (data) => {
    if (data.id === table.package) {
      tableDispatch({ type: "SET_PACKAGE", data: null });
    }
    actions.sendAnalyticsData({
      hitType: "event",
      eventAction: "Selection",
      eventLabel: `Packages - ${data.title}`,
    });
    tableDispatch({ type: "SET_PACKAGE", data: data.id });
  };


  const bookingDay = useMemo(()=> {
    const date = parse(table.date, "yyyy-MM-dd", new Date());
    return format(date, "EEEE");
  }, [])

  const packages = useMemo(() => {
    return table.packages.filter((item) => {
      if(!item.availableDays) return true;
      return item.availableDays.includes(bookingDay);
    })
  }, [table, bookingDay])

  return (
    <>
      <Back
        onClick={handleBack}
      >
        <span>Back</span>
      </Back>
      {!isMandatory && (
        <>
          <Intro>Add a package to your booking?</Intro>
          <Description>
            These may be mandatory when booking at peak times. Our team will contact you to discuss specific options and pricing.
          </Description>
        </>
      )}
      {isMandatory && (
        <Copy>
          Due to your requested booking date, please select a package to continue with your booking request:
        </Copy>
      )}
      <GridWrap>
        {Array.from(packages, (data) => (
          <Package
            key={data.id}
            data={data}
            click={setPackage}
            active={table.package === data.id}
          />
        ))}
      </GridWrap>
      <Description>
        When selecting a package this will be ready for you on arrival. Any
        changes must be made with 72 hours of your booking.
      </Description>
      {isMandatory && (
        <Copy><strong>Please select a package before continuing.</strong></Copy>
      )}
      <ButtonGroup>
        <Button
          active
          disabled={isMandatory && !table.package}
          onClick={handleNextStep}
        >
          Next Step
        </Button>
      </ButtonGroup>
    </>
  );
};

export default Packages;
