import styled from "styled-components";

import Text from "./Text";

const Distance = styled(Text)`
  margin-top: auto;
  margin-bottom: 16px;
`;

export default Distance;
