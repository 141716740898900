import React from "react";
import { dateFormat } from "../helper";
import Button from "./ui/Button";
import ButtonGroup from "./ui/ButtonGroup";
import Field from "./ui/Field";
import Flatpickr from "./ui/Flatpickr";

const GuestLineForm = ({
  state,
  setState,
  venueName,
  guestLineCode,
  handleCheckInChange,
  handleCheckOutChange,
  handleOpen,
  today,
}) => {
  return (
    <form
      action={`https://${(
        state.venue ?? guestLineCode
      ).toLowerCase()}.dbm.guestline.net/availability`}
      method="get"
      _lpchecked="1"
      target="_blank"
    >
      {state.venues.length > 0 && (
        <Field label="Select a Venue" value={venueName}>
          <select
            name="hotelCode"
            onChange={(e) => {
              const {
                target: { value = "" },
              } = e || { target: {} };

              if (setState) {
                setState((previousState) => ({
                  ...previousState,
                  venue: value,
                }));
              }
            }}
            required
            value={state.venue || ""}
          >
            <option value="" aria-label="Select Option" />
            {state.venues.map((venue) => (
              <option
                key={`venue-field-option-${venue.guestLineId || venue.etchId}`}
                value={venue.guestLineId || venue.etchId}
              >
                {venue.title}
              </option>
            ))}
          </select>
        </Field>
      )}
      {!state.venues.length && (
        <input
          type="hidden"
          name="hotel"
          id="hotelCode"
          value={guestLineCode}
        />
      )}
      <Field
        label="Check In"
        value={state.checkIn ? dateFormat(state.checkIn) : "Select Date"}
      >
        <Flatpickr
          defaultDate={state.checkIn}
          handleChange={handleCheckInChange}
          handleOpen={handleOpen}
          minDate={today}
        >
          <input name="arrival" type="text" />
        </Flatpickr>
      </Field>
      <Field
        label="Check Out"
        value={state.checkOut ? dateFormat(state.checkOut) : "Select Date"}
      >
        <Flatpickr
          defaultDate={state.checkOut}
          handleChange={handleCheckOutChange}
          handleOpen={handleOpen}
          minDate={today}
        >
          <input name="departure" type="text" />
        </Flatpickr>
      </Field>
      <ButtonGroup>
        <Button active>Check Availability</Button>
      </ButtonGroup>
    </form>
  );
};

export default GuestLineForm;
