import styled from "styled-components";

const Wrapper = styled.section`
  display: ${props => props.isVisible ? "block" : "none"};
  font-size: 14px;
  line-height: 18px;
  color: white;

  .theme--light & {
    color: black;
  }

  > strong {
    display: block;
  }

  > strong,
  > p {
    margin-bottom: 20px;
  }
`

export default Wrapper;
