import styled from "styled-components";

const PopupTrigger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 10px;
  height: 15px;
  width: 15px;
  color: white;
  border: 1px solid white;
  font-size: 10px;
  cursor: pointer;

  &:hover {
    background: white;
    color: black;
  }
`;

export default PopupTrigger;
