import styled from "styled-components";

const Inner = styled.div`
  position: relative;
  margin: 20px;
  background: white;
  width: 100%;
  max-width: 440px;
  padding: 33px 20px 20px;

  & h3 {
    margin-top: 10px;
    font-size: 16px;
  }

  small {
    font-size: smaller;
  }
`;

export default Inner;
