import styled from "styled-components";

import Base from "../../../../ui/Button";

const Button = styled(Base)`
  margin-left: auto;
  padding: 3px 5px;
  min-width: 125px;
  text-align: center;
  text-transform: none;
  background: ${(props) =>
    props.active ? "var(--colour-tertiary-dmn)" : "transparent"};
  border-color: ${(props) =>
    props.active ? "var(--colour-tertiary-dmn)" : "white"};
  
  &:hover {
    color: 'Nunito Sans';
  }

  @media (max-width: 500px) {
    margin-right: auto;
  }
`

export default Button;
