import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";

import BookTable from "./components/BookTable";
import BookRoom from "./components/BookRoom";
import BookOther from "./components/BookOther";
import { getParams } from "./helper";
import TypeSelector from "./components/TypeSelector";
import { TableProvider } from "./store/table";
import getFont from "./utils/getFont";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100%;
  background: rgba(0, 0, 0, 0.33);
  transition: 0.4s opacity ease-in-out;
  opacity: ${(props) => (props.active ? "1" : "0")};
  pointer-events: ${(props) => (props.active ? "auto" : "none")};

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;

    @media (max-width: 500px) {
      -webkit-overflow-scrolling: touch;
    }
  }
`

const Wrapper = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  width: calc(100% - 20px);
  max-width: 705px;
  height: 100%;
  min-height: 100vh;
  max-height: 100%;
  background: #1c1c1c;
  background-clip: content-box;
  overflow: auto;
  font-family: ${getFont()};
  transition: 0.4s ease-in-out;
  transform: ${(props) =>
    props.active ? "translateX(0)" : "translateX(100%)"};
  opacity: ${(props) => (props.active ? "1" : "0")};

  &.theme--light {
    background: #fff;
    background-clip: content-box;
    font-family: ${getFont()};
  }

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;

    @media (max-width: 500px) {
      -webkit-overflow-scrolling: touch;
    }
  }
`;


const Actions = styled.aside`
  display: flex;
  justify-content: flex-end;
  padding: 5px 20px;
`;

const Close = styled.button`
  position: relative;
  width: 30px;
  height: 30px;
  opacity: 0.8;
  padding: 0;
  background: 0;
  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: 7px;
    content: " ";
    height: 30px;
    width: 1.5px;
    top: 0;
    background-color: rgb(203 199 199);

    .theme--light & {
      background-color: black;
    }
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  width: calc(100% - 20px);
  max-width: 705px;
  height: 100%;
  min-height: 100vh;
  max-height: 100%;
  pointer-events: none;
`

const App = ({ venueId, endpoint, optionalPanels }) => {

  function getTypeDefault() {
    return optionalPanels.book_a_table_enabled ? "book-a-table" : "book-a-room"
  }

  const modalRef = createRef();

  const [type, setType] = useState(getTypeDefault());
  const [active, setActive] = useState(false);
  const [venueIdOverride, setVenueIdOverride] = useState(venueId);
  const [siteNameOverride, setSiteNameOverride] = useState(undefined);



  const closeHandler = () => setActive(false);

  const openHandler = (event) => {
    if (typeof event !== "undefined") {
      const { detail: { initialType } } = event;

      if (["book-a-room", "book-a-table", "general"].includes(initialType)) {
        setType(initialType);
      }

      if (event.detail.localVenueId) {
        setVenueIdOverride(event.detail.localVenueId)
      }
      if (event.detail.siteName) {
        setSiteNameOverride(event.detail.siteName)
      }
    }

    setActive(true);
  };

  const theme = venueId === "corporate" ? "light" : "dark";

  useEffect(() => {
    window.addEventListener("openWidget", openHandler, false);
    return function cleanup() {
      window.removeEventListener("openWidget", openHandler);
    };
  });

  useEffect(() => {
    window.addEventListener("closeWidget", closeHandler, false);
    return function cleanup() {
      window.removeEventListener("closeWidget", closeHandler);
    };
  });

  const getPanelType = () => {
    if (type === "book-a-table") return "Book a Table";
    if (type === "book-a-room") return "Book a Room";
    // eslint-disable-next-line react/prop-types
    return optionalPanels.general_label || "Call Us";
  };

  useEffect(() => {
    const params = getParams(window.location.search);

    if (params.booking_type && params.booking_type_category) {
      openHandler();
    }
  }, []);

  return (
    <>
      <Overlay active={active} />
      <Wrapper active={active} className={`theme--${theme}`} ref={modalRef} data-dmn-active={active}>
        <Actions>
          <Close onClick={window.closeDmnBookingWidget} />
        </Actions>
        <TableProvider
          key={`TP-${venueIdOverride}`}
          venueId={venueIdOverride}
          endpoint={endpoint}
          isActive={active}
          panelType={getPanelType()}
        >
          <TypeSelector
            optionalPanels={optionalPanels}
            set={setType}
            type={type}
          />
          {type === "book-a-table" && <BookTable modalRef={modalRef} siteName={siteNameOverride} />}
          {type === "book-a-room" && <BookRoom modalRef={modalRef} optionalPanels={optionalPanels} />}
          {type === "general" && <BookOther optionalPanels={optionalPanels} />}
        </TableProvider>
      </Wrapper>
      <Modal id="modal-root" />
    </>
  );
};

export default App;
