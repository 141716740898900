import styled from "styled-components";

import { Hero } from "../../../../ui/PackageHero";

const Wrapper = styled.article`
  display: ${props => props.isVisible ? "flex" : "none"};
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #5f5f5f;

  @media (max-width: 500px) {
    flex-wrap: wrap;
  }

  .theme--light & {
    border-top: 1px solid #black;
  }

  &:first-of-type {
    margin-top: 0;
    border-top: none;
  }

  > ${Hero} {
    max-width: 88px;
    margin-bottom: 0;

    @media (max-width: 500px) {
      max-width: none;
    }
  }
`

export default Wrapper;
