/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect } from 'react'
import Button from './ui/Button'
import ButtonGroup from './ui/ButtonGroup'
import Field from './ui/Field'
import Flatpickr from './ui/Flatpickr'
import { dateFormat } from '../helper'

export default function ProfitRoomForm({
  state,
  handleCheckInChange,
  handleCheckOutChange,
  handleOpen,
  hotelName
}) {
  const today = new Date().toISOString().split('T')[0]


  const formSubmit = useCallback(
    event => {
      event.preventDefault()
      const bookingData = {
        checkin: state.checkIn,
        checkout: state.checkOut,
        Currency: 'GBP'
      }
      window.Booking.OpenSite(hotelName, bookingData)
    },
    [state]
  )

  useEffect(() => {
    if (!window.Booking) {
      const pScript = document.createElement('script')

      pScript.setAttribute('src', `//open.upperbooking.com/js/Booking.js`)

      document.head.appendChild(pScript)
    }
  }, [])

  return (
    <form onSubmit={formSubmit}>
      <Field
        label='Check In'
        value={state.checkIn ? dateFormat(state.checkIn) : 'Select Date'}
      >
        <Flatpickr
          defaultDate={state.checkIn}
          handleChange={handleCheckInChange}
          handleOpen={handleOpen}
          minDate={today}
        >
          <input name='arrival' type='text' />
        </Flatpickr>
      </Field>
      <Field
        label='Check Out'
        value={state.checkOut ? dateFormat(state.checkOut) : 'Select Date'}
      >
        <Flatpickr
          defaultDate={state.checkOut}
          handleChange={handleCheckOutChange}
          handleOpen={handleOpen}
          minDate={today}
          position="below left"
        >
          <input name='departure' type='text' />
        </Flatpickr>
      </Field>
      <ButtonGroup>
        <Button active>Check Availability</Button>
      </ButtonGroup>
    </form>
  )
}
