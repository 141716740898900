import React, { useEffect, useState } from "react";
import { addMinutes, eachMinuteOfInterval, format } from "date-fns";

import Field from "./Field";
import { weekdays } from "../../helper";

function getOptions(timeslots) {
    return timeslots.map(({ time }) => ({ label: time, value: time }));
}

async function getTimes({ bookingTypeId, date, endpoint, numPeople, venueId }) {
    try {
        const response = await fetch(`${endpoint}/v1/venue/${venueId}/timeslots?date=${date}&bookingType=${bookingTypeId}&numPeople=${numPeople}`, {
            headers: { "Content-Type": "application/json" },
        });

        const { dateSettings = {}, timeslots = [] } = await response.json();

        return { dateSettings, timeslots };
    } catch (error) {
        console.error(`[Propeller DMN] - ${error.message}`);
    }
}

function hasValidNumPeople({ date, numPeople, rules }) {
    if (date && Object.keys(rules).length > 0) {
        try {
            const weekday = weekdays[new Date(date).getDay()];
            const weekdayRules = rules[weekday];

            const { minPeople, maxPeople } = weekdayRules;

            return numPeople >= minPeople && numPeople <= maxPeople;
        } catch (error) {
            throw new Error(`[Propeller DMN] - ${error}`);
        }
    }

    return false;
}

const StartTimeField = ({ apiEnabled, bookingTypeId, date, endpoint, numPeople, rules, tableDispatch, timeslots: stateTimeslots = [], value, venueId, handleTimeStartChange }) => {
    const [options, setOptions] = useState(getOptions(stateTimeslots));

    useEffect(() => {
        if (bookingTypeId && date && numPeople) {
            const reloadOptions = async () => {
                tableDispatch({ type: "SET_CROSS_SELL_VISIBLE", data: false },);

                const { dateSettings: { maxDuration, minDuration }, timeslots } = await getTimes({ bookingTypeId, date, endpoint, numPeople, venueId });

                const actions = [
                    { type: "SET_MAX_DURATION", data: maxDuration },
                    { type: "SET_MIN_DURATION", data: minDuration },
                    { type: "SET_TIMESLOTS", data: timeslots }
                ];
                let endTimeslots = [];
                let newOptions = [];

                if (timeslots.length > 0) {
                    actions.push({ type: "SET_ERROR", data: [] });

                    const { availableOffers, time: firstTimeslot } = timeslots[0];
                    const { time: lastTimeslot } = timeslots[timeslots.length - 1];

                    const firstDateTime = new Date(`${date}T${firstTimeslot}:00`);
                    const lastDateTime = addMinutes(new Date(`${date}T${lastTimeslot}:00`), maxDuration);

                    const intervals = eachMinuteOfInterval({ start: firstDateTime, end: lastDateTime }, { step: 15 });

                    endTimeslots = intervals.map((interval) => format(interval, "HH:mm"));

                    newOptions = getOptions(timeslots);

                    if (newOptions.length && !newOptions.some((option) => `${option.value}` === `${value}`)) {
                        actions.push({ type: "SET_AVAILABLE_OFFERS", data: availableOffers });
                        actions.push({
                            type: "SET_TIME",
                            data: newOptions[0].value,
                        });
                    }
                } else {
                    actions.push({ type: "SET_AVAILABLE_OFFERS", data: [] });
                    actions.push({ type: "SET_DURATION", data: "" });
                    actions.push({ type: "SET_CROSS_SELL_VISIBLE", data: true });
                    actions.push({ type: "SET_TIME", data: "" });
                }

                actions.push({ type: "SET_END_TIMESLOTS", data: endTimeslots });

                tableDispatch(actions);

                setOptions(newOptions);
            }

            if (hasValidNumPeople({ date, numPeople, rules })) {
                if (apiEnabled) {
                    reloadOptions();
                }
            }
        }
    }, [apiEnabled, bookingTypeId, date, numPeople]);

    useEffect(() => {
        if (!date && value) {
            tableDispatch([
                { type: "SET_DURATION", data: "" },
                { type: "SET_TIME", data: "" }
            ]);

            setOptions([]);
        }
    }, [date]);

    useEffect(function setInitialTime(){
      if (options[0]) {
        handleTimeStartChange(
          {time: `${options[0].value}`}
        );
      }
    }, [options]);

    return (
        <Field label="Start Time" value={!options.length ? "" : value}>
            <select
                disabled={!options.length}
                value={value}
                onChange={(e) => handleTimeStartChange({ time: e.target.value })}
            >
                {options.map((option) => <option key={`start-time-field-option-${option.value}`} value={option.value}>{option.label}</option>)}
            </select>
        </Field>
    )
}

export default StartTimeField;
