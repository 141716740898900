import React from "react";
import styled from "styled-components";

import Button from "./ui/Button";
import Flex from "./ui/Flex";
import { sendAnalytics } from "../helper";

const Tab = styled(Button)`
  margin-right: -0.5px;
  margin-left: -0.5px;
`;

const TypeSelector = ({ type, set, optionalPanels }) => {
  const {
    book_a_room_enabled: bookARoomEnabled,
    book_a_room_label: bookARoomLabel,
    book_a_table_enabled: bookATableEnabled,
    general_enabled: generalEnabled,
    general_label: generalLabel,
  } = optionalPanels;

  const handleTypeChange = ({ value, label }) => {
    if (type === value) return;
    sendAnalytics({
      hitType: "event",
      eventAction: "Navigate",
      eventLabel: `Panel - ${label}`,
    });
    set(value);
  };

  return (
    <Flex>
      {bookATableEnabled && (
      <Tab
        onClick={() => {
            handleTypeChange({ value: "book-a-table", label: "Book a Table" });
          }}
        active={type === "book-a-table"}
      >
        Book a Table
      </Tab>
      )}
      
      {bookARoomEnabled && (
        <Tab
          onClick={() => {
            handleTypeChange({
              value: "book-a-room",
              label: bookARoomLabel || "Book a Room",
            });
          }}
          active={type === "book-a-room"}
        >
          {bookARoomLabel || "Book a Room"}
        </Tab>
      )}
      {generalEnabled && (
        <Tab
          onClick={() => {
            handleTypeChange({
              value: "general",
              label: generalLabel || "Call Us",
            });
          }}
          active={type === "general"}
        >
          {generalLabel || "Call Us"}
        </Tab>
      )}
    </Flex>
  );
};

export default TypeSelector;
