import React, { useCallback, useEffect, useMemo, useState } from "react";

import Button from "../../ui/Button";
import ButtonGroup from "../../ui/ButtonGroup";
import CrossSellVenue from "./CrossSellVenue/CrossSellVenue";
import * as StyledCrossSell from "./styled";
import useClosestVenues from "./hooks/useClosestVenues";

const venuesPerPage = 3;

function getVenuePage(venueIndex) {
  return Math.ceil(venueIndex / venuesPerPage) * venuesPerPage;
}

const initialState = { page: 1, resolvedVenues: [], venuesToResolve: [] };

const CrossSell = ({ bookingType, bookingTypeTitle, crossSellTypes, date, endpoint, isVisible, numPeople, venueId }) => {
  const [state, setState] = useState(initialState);

  const handleNextPage = () => setState(
    (previousState) => ({ ...previousState, page: previousState.page + 1 })
  );

  const handleResolveVenue = useCallback((resolvedVenueId) => setState(
    (previousState) => ({ ...previousState, resolvedVenues: [...previousState.resolvedVenues, resolvedVenueId] })
  ), []);

  const isResolved = useMemo(
    () => state.venuesToResolve.length > 0 && state.venuesToResolve.every(
      (venue) => state.resolvedVenues.includes(venue)
    ),
    [state.resolvedVenues, state.venuesToResolve]
  );

  const { venues } = useClosestVenues({ endpoint, isEnabled: isVisible, venueId });

  useEffect(() => {
    if (!isVisible && setState) {
      setState((previousState) => ({
        ...previousState,
        ...initialState,
        timestamp: Date.now(),
      }));
    }
  }, [isVisible])

  useEffect(() => {
    if (typeof venues !== "undefined") {
      const crossSellVenues = venues.reduce((previousVenues, venue) => {
        const crossSellType = crossSellTypes.find((type) => type.venueId === venue.id);

        if (crossSellType) {
          return [
            ...previousVenues,
            { ...venue, bookingType: crossSellType.bookingTypeId, bookingTypeCategory: crossSellType.bookingTypeCategoryId }
          ];
        }

        return previousVenues;
      }, []);

      setState((previousState) => ({ ...previousState, venues: crossSellVenues }));
    }
  }, [crossSellTypes, venues]);

  const totalPages = useMemo(() => {
    if (typeof state.venues !== "undefined") {
      return state.venues.length / venuesPerPage;
    }
    return 0;
  }, [state.venues]);

  useEffect(() => {
    if (typeof state.venues !== "undefined") {
      const pageTotal = state.page * venuesPerPage;

      const nextVenuesToResolve = [...Array(venuesPerPage)].reduce(
        (previous, _, index) => ([...previous, state.venues[pageTotal - (index + 1)]?.id]),
        []
      ).filter(Boolean);

      setState((previousState) => ({ ...previousState, venuesToResolve: nextVenuesToResolve }));
    }
  }, [state.page, state.timestamp, state.venues])

  return bookingType && date && numPeople ? (
    <StyledCrossSell.Wrapper isVisible={isVisible && typeof state.venues !== "undefined"}>
      <strong>
        Apologies, we have no availability on that date for our 
        {' '}
        {bookingTypeTitle}
        .
      </strong>
      <p>
        We may however, have availability for
        {' '}
        {bookingTypeTitle}
        {' '}
        at our nearby venues:
      </p>
      {typeof state.venues !== "undefined" && state.venues.map(
        (venue, index) => {
          const venuePage = getVenuePage(index + 1);

          return (
            <CrossSellVenue
              date={date}
              endpoint={endpoint}
              isVisible={isVisible && venuePage <= (state.page * venuesPerPage)}
              numPeople={numPeople}
              key={`cross-sell-venue-${venue.id}`}
              onResolve={handleResolveVenue}
              venue={venue}
            />
          );
        }
      )}
      {totalPages && totalPages > state.page ? (
        <ButtonGroup>
          <Button
            active
            disabled={!isResolved}
            onClick={handleNextPage}
          >
            Check More
          </Button>
        </ButtonGroup>
      ) : null}
    </StyledCrossSell.Wrapper>
  ) : null
};

export default CrossSell;
